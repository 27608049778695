#root {
  height: 100vh;
  width: 100vw;
  position: relative;
}

body {
  margin: 0px;
  min-height: 100vh;
}

#dom-root {
  height: 100%;
}

